import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";


const CarouselCom = () => {

  return (
    <Carousel 
    autoPlay={true}
    infiniteLoop={true}
    interval={3000}
    showThumbs={false}
    showStatus = {false}
    >
     <div className="flex flex-col  md:flex-row gap-6  justify-between mt-20 w-full bg-gray-100 p-6">
  <div className="md:w-1/2 flex flex-col justify-center">
    <h5 className="text-xl font-bold mb-4">Comprehensive Custom HR Solutions!</h5>
    <p className="mb-6 text-justify">
      From HR operations to strategic business consulting, we deliver bespoke solutions tailored to your needs. Our services span HR strategy, performance management, policy development, HRBP, payroll, compliance, and employee engagement. We meticulously design training, development, and succession planning solutions to align perfectly with your unique requirements.
    </p>
    <Link to="/contactus" className="w-60 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300">
      Get in Touch Today!
    </Link>
  </div>
  <div className="md:w-1/2">
    <img src="/assets/images/home_banner2.png" alt="homeimg" className="w-full rounded shadow-lg" />
  </div>
</div>
<div className="flex flex-col  md:flex-row gap-6  justify-between mt-20 w-full bg-gray-100 p-6">
  <div className="md:w-1/2">
    <img src="/assets/images/home_banner1.jpg" alt="homeimg" className="w-full rounded shadow-lg" />
  </div>
  <div className="md:w-1/2 flex flex-col justify-center">
  <h5 className="text-2xl font-bold mb-4">Tailored HR and Business Consulting Solutions</h5>
  <p className="mb-6 text-justify">
  We differentiate ourselves through a client-centric approach that begins with deep comprehension of your unique challenges and aspirations. Our solutions are meticulously crafted to not only address current issues but also propel your organization towards sustained success. We excel in aligning HR strategies, optimizing performance management, and fostering a culture of compliance and employee engagement.
    </p>
    <Link to="/contactus" className="w-60 bg-blue-500  text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300">
    Contact Us Today!
    </Link>
  </div>

</div>

</Carousel>
  )
}

export default CarouselCom