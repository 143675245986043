import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "../../ui/sheet";
import { Separator } from "../../ui/separator";
/*   import NavItems from "./NavItems" */
import { FaBars } from "react-icons/fa";
import { SubNavigation } from "./NavItems";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport,
  navigationMenuTriggerStyle,
} from "../../../components/ui/navigation-menu"
import { MobileLinks } from "../../../constants/index";
import { Link } from "react-router-dom";


const MobileNav = () => {
  return (
    <nav className="md:hidden sticky top-0">
    <Sheet key="right">
      <SheetTrigger className="align-middle">
        <FaBars size="26px" color="black"/>
      </SheetTrigger>
      <SheetContent className="flex flex-col gap-6 bg-white md:hidden" side="right">
        <img 
          src="/assets/images/logo.jpeg"
          alt="logo"
          width={108}
          height={28}
        />
        <Separator className="border border-gray-100" />
        <div>
          <NavItemsMobile/>
        </div>
      </SheetContent>
    </Sheet>
  </nav>
  );
};

const NavItemsMobile = ()=>{
  return<NavigationMenu>
  <NavigationMenuList className="flex flex-col gap-2 items-start">
   {MobileLinks.map((links:any)=> <NavigationMenuItem key={links.route}>
   <Link to={links.route} className={`${navigationMenuTriggerStyle()} hover:text-blue-400 md:text-blue-400 max-w-[280px] text-wrap`}> {links.label}
   </Link>
    </NavigationMenuItem>)}
  </NavigationMenuList>
</NavigationMenu>

}

export default MobileNav;
